import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	IPropsStore,
	Props,
	PropsMap,
	CurrentRouteInfoSymbol,
	SiteFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo, IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { SocialUrlPageConfig, SocialUrlSiteConfig } from './types'
import { name } from './symbols'

const SOCIAL_URL_PROP_NAME = 'socialUrl'

const resolveSocialUrl = (currentUrl: URL, useHttps: boolean) => {
	const url = new URL(currentUrl.toString())

	url.protocol = useHttps ? 'https:' : 'http:'
	url.search = ''
	url.hash = ''

	return url.toString().replace(/\/$/, '')
}

const resolveHashBangUrl = (
	forceMainPage: boolean,
	pageId: string,
	relativeUrl: string,
	baseUrl: string,
	useHttps: boolean
) => {
	const [, pageUriSeo, ...additionalUrlParts] = relativeUrl.split('/')
	const additionalUrl = additionalUrlParts.length ? `/${additionalUrlParts.join('/')}` : ''

	// Replace domain to the old wix.com domain
	const oldBaseUrls = baseUrl.replace('wixsite.com', 'wix.com')
	const url = new URL(`${oldBaseUrls}`)
	url.protocol = useHttps ? 'https:' : 'http:'

	if ((!forceMainPage && pageUriSeo) || !pageId) {
		url.hash = `!${pageUriSeo}/${pageId}${additionalUrl}`
	}
	return url.toString()
}

const socialUrlFactory = (
	pageConfig: SocialUrlPageConfig,
	siteConfig: SocialUrlSiteConfig,
	propsStore: IPropsStore,
	urlHistoryManager: IUrlHistoryManager,
	currentRouteInfo: ICurrentRouteInfo
): IPageWillMountHandler => {
	const mainPageUrl = new URL(siteConfig.mainPageUrl)

	return {
		async pageWillMount() {
			const updatePropsMap = Object.entries(pageConfig).reduce<PropsMap>(
				(result, [compId, { forceMainPage, useHttps, useHashBangUrl }]) => ({
					...result,
					[compId]: {
						[SOCIAL_URL_PROP_NAME]: useHashBangUrl
							? resolveHashBangUrl(
									forceMainPage,
									currentRouteInfo.getCurrentRouteInfo()!.pageId,
									urlHistoryManager.getRelativeUrl(),
									siteConfig.mainPageUrl,
									useHttps
							  )
							: resolveSocialUrl(
									forceMainPage ? mainPageUrl : urlHistoryManager.getParsedUrl(),
									useHttps
							  ),
					},
				}),
				{}
			)

			propsStore.update(updatePropsMap)
		},
	}
}

export const SocialUrl = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(SiteFeatureConfigSymbol, name),
		Props,
		UrlHistoryManagerSymbol,
		CurrentRouteInfoSymbol,
	],
	socialUrlFactory
)
